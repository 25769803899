import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faChartLine,faUsers,faCogs,faHome,faTools,faComments,faLocation,faLocationPin } from '@fortawesome/free-solid-svg-icons'
import { fab,faFacebook, faTwitter,faInstagram,faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { useState,useEffect } from 'react';
import { axiosPrivate } from "../../api/axios"
import LogoImg from "../../Components/images/ntumaiLogo.png"

import "./AdminView.css"
import { Link } from 'react-router-dom';
import NavBar from '../NavBar/NavBar';
import NavSec from '../NavSec/NavSec';

function AdminView(){  

    const [Tasks, setTasks] = useState([{}]);
    const [statusValue, setstatusValue] = useState();
    const tData = [
        {
            "ID": "12422424",
            "CustomerName": "Ignatius Musonda",
            "Price": "300",
            "Date": "20/05/2023",
            "Status": "Pending",
        },
        {
            "ID": "12422424",
            "CustomerName": "Vincent Musonda",
            "Price": "300",
            "Date": "20/05/2023",
            "Status": "Stuck",
        },
        {
            "ID": "12422424",
            "CustomerName": "Mwamba Musonda",
            "Price": "300",
            "Date": "20/05/2023",
            "Status": "Delivered",
        },
      
    ]


    useEffect(() => {

      
  
        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/Orders', {
                    // signal: controller.signal,
                    // params: {
                    //   email: auth.user,
                    //   AccountNo: auth.foundUser.AccountNo,
                    //   org: 'value2',
                    //   mgtLvl: 'value2',
                    // }
                });
                
                // console.log("init tdata")
  
                if(response?.data){
                    console.log("In admin",response)
                    setTasks(response.data.propdata);
                    // console.log("tasks",Tasks)
                    // if(response.data?.propdata){
                    //   console.log(response.data);
                    //   console.log("Here");
                    //   setTData(response?.data?.propdata);
                    //   console.log("tdata",tData)
  
                }
                
              //   isMounted && setUsers(response.data);
            } catch (err) {
                console.error(err);
              //   navigate('/login', { state: { from: location }, replace: true });
            }
        }
        // console.log("contect",auth)
        getUsers();
        console.log("tasks",Tasks)
  
      
    }, [])

    const handleUpdate = (item) =>{
        console.log("I am clicked ",item)
        console.log("I am clicked ",statusValue)
    }

    const handleChange = (e) => {
        setstatusValue(e.target.value);
    };
  


    async function handleSubmit(e,item) {  
        // async function registerUser(e) {  
            e.preventDefault(); 
         
             console.log("event",e);
             console.log("item",item)
           
    
         
        
             ////
             const response = await axiosPrivate.post("/Orders/AdmnOrders",
            //  const response = await axiosPrivate.post("http://localhost:2000/users",
                JSON.stringify({
                   
                statusValue,
                ID : item
    
                    
                
                 }),
                // JSON.stringify({ user, pwd }),
                {
                    headers: { 'Content-Type': 'application/json',
                   
                 },
                    
                    withCredentials: true,
                    crossDomain: true,
                    
                }
            );
             ////
            console.log(response)
     
          
    
              if(response?.data?.status == 'Ok'){

                // Reload orders on status change
                try {
                    const response = await axiosPrivate.get('/Orders', {
                     
                    });
                    
      
                    if(response?.data){
                        console.log("In admin",response)
                        setTasks(response.data.propdata);
                        setstatusValue("Update Status")
                      
      
                    }

                    alert("Status updated Successfully")
                    
                  
                } catch (err) {
                    console.error(err);
                  
                }
                
               
                
              }else{
              
               
              }
        }


        // handle view cart items
        const handleCartClick = (itemx) => {
            const formattedData = itemx?.Cart.map(itemx => {
              return `Name: ${itemx?.Name} | Count: ${itemx?.count} | Price: zmk ${itemx?.Price}`;
            });
            alert(formattedData.join('\n'));
          };
    



    return <>
      {/* <NavSec/> */}
      <div>
      <div className="loggedHeader">
                    <div className="Logo" onClick={()=>{console.log("I have been clicked")}}>
                    <Link className="textLink" to="/"><img src={LogoImg} /></Link>
                        
                    </div>
                    <div className="SignIn">
                        <h1>{""}</h1>
                        {/* <h1>{(auth?.foundUser?.name).Substring(0,1)}</h1> */}
                    </div>
                    
                  </div>
      </div>
        <div className="adminContainer"> 

        <div className="SideBar">
            {/* <div className='topItem'>
                <h2><Link className='LinkItem' to="/">Ntumai</Link></h2>
            </div> */}
            <ul>
                <li ><FontAwesomeIcon className='fontAwesomeItem' icon={faChartLine}/>Orders</li>
                <li ><FontAwesomeIcon className='fontAwesomeItem' icon={faCogs}/>Merchants</li>
                <li ><FontAwesomeIcon className='fontAwesomeItem' icon={faUsers}/>Customers</li>
                <li ><FontAwesomeIcon className='fontAwesomeItem' icon={faHome}/>Team</li>
            </ul>

        </div>

        <div className="outlet">    
         <div className="Heading">

            <h1></h1>

         </div>
         <div className="Table">    

         <table className="contentTable"> 
                                <thead>
                                    <tr>
                                        {/* <th></th> */}
                                        <th>Order #</th>
                                        <th>Status</th>
                                        <th>Set Status</th>
                                        <th>Date</th>
                                        <th>Cart</th>
                                        <th>Start</th>
                                        <th>Destination</th>
                                        <th>Carrier</th>
                                        <th>Customer Name</th>
                                        <th>Phone</th>
                                        <th>Meters</th>
                                        <th>Minutes</th>
                                        
                                        {/* <th>Start latitude</th>
                                        <th>Start longitude</th>
                                        <th>End latitude</th>
                                        <th>End longitude</th> */}
                                        
                                        
                                        <th></th>
                                        {/* <th>TID</th>
                                        <th>Recepient</th> */}

                                        {/* here */}
                                        
                                    </tr>
                                </thead>
                                <tbody>


                                {/*
                                 var isod =  new Date(item.TDate).toISOString();
                                    //   var dates = format(isod, 'yyyy-MM-dd') 
                                    //    var datee = new Date(item.TDate)
                                    //    console.log("Date here",isod.toString().substring(2, 10))
                                    return(  
                                    <tr>

                                        <td>{index+1}</td>
                                        <td>{isod.toString().substring(2, 10)}</td>
                                         */}




                                      
                                  
                                

                                      {Tasks && Tasks
                                      .sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate))
                                      .map((item,index)=>{


                                        var isod =  new Date(item?.OrderDate)
                                        
                                     

                                      
                                    //   var dates = format(isod, 'yyyy-MM-dd') 
                                    //    var datee = new Date(item.TDate)
                                    //    console.log("Date here",isod.toString().substring(2, 10))
                                    return(  
                                    <tr >

                                        <td>{index+1}</td>
                                        <td>{item.Status}</td>
                                        <td>
                                        <select value={statusValue} name="cars" onChange={handleChange}>
                                                <option value="Pending">Update Status</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Driver Assigned">Driver Assigned</option>
                                                <option value="Deliverying">Deliverying</option>
                                                <option value="Completed">Completed</option>
                                                <option value="Cancelled">Cancelled</option>
                                        </select>
                                        </td>
                                        <td>{isod?.toString().substring(3, 15)}</td>
                                        <td 
                                            onClick={(e)=>{
                                                // Stop event propagation
                                                e.stopPropagation();
                                                handleCartClick(item)
                                                
                                             
                                            }}
                                        >{item?.Cart?.length>0 ?item?.Cart?.length: "Empty"}</td>
                                        <td>{item?.startAddress}</td>
                                        <td>{item?.DAddress}</td>
                                        <td>{item.carrier}</td>
                                        {/* <td>{item.OrderDate}</td> */}
                                        <td>{item?.name} </td>
                                        <td>{item?.PhoneNumber} </td>
                                       
                                        {/* <td>{new Date()}</td> */}
                                        
                                        <td>{item?.distance}</td>
                                        <td>{item?.duration}</td>
                                       
                                        {/* <td>{item.SLat}</td>
                                        <td>{item.SLng}</td>
                                        <td>{item.ELat}</td>
                                        <td>{item.ELng}</td> */}
                                        
                                        {/* <td>{item.carrier}</td> */}
                                      
                                       
                                        <td><button onClick={(e)=>handleSubmit(e,item?._id)}>
                                                Update Status
                                            </button></td>
                                        {/* <td>{item.transactionID}</td>
                                        <td>{item.TAccount}</td> */}
                                        
                                    </tr>
                                  )
                                })} 

                                       <tr>

                                        <td><b>{""}</b></td>
                                        <td>{""} </td>
                                        <td>{""}</td>
                                        {/* <td>{new Date()}</td> */}
                                        
                                        <td>{""}</td>
                                        
                                        
                                    </tr>
                                  
                                
                                </tbody>
                            </table>


         </div>

       
           

        </div>


        
           
     
        </div>
        
    </>

}

export default AdminView;