





import { Link } from "react-router-dom";
import "./ProductOrder.css"
import { useNavigate, useParams } from 'react-router';



// import img1 from "../Images/Alcon1.jpg"
// import img1 from "../../Components/Images/Alcon1.jpg"
// import img2 from "../../Components/Images/Alcon2.jpg"
// import img3 from "../../Components/Images/Alcon7.jpg"

import img1 from "../../Components/images/img1.jpg"
import LogoImg from "../../Components/images/ntumaiLogo.png"
import img2 from "../../Components/images/img1.jpg"
import img3 from "../../Components/images/img1.jpg"
import NavBar from "../../Components/NavBar/NavBar";
// import { useState } from "react";
import SlideCarousel from "../../Components/SlideCarousel/SlideCarousel";
import NavSec from "../../Components/NavSec/NavSec";

import useAuth from "../../Hooks/UseAuth";
import ScrollToTop from "../../Components/Scrolltop/Scrolltop";

import Footer from "../../Components/Foooter/Footer";
// import ScrollToTop from "../../Components/Scrolltop/Scrolltop";


import useContentful from "../../Hooks/Contentful";
import { useEffect,useState } from "react";

// function Listing(){  

 

function ProductOrder({ handleCart}) {

  const { getAuthors } = useContentful();

  useEffect(() => {
      console.log("Hello")
   // getAuthors().then((response) => console.log("ressedagt",response));
    getAuthors().then((response) => response && setCardData(response));
  },[]);

  const [cardData, setCardData] = useState()


   const {auth,setAuth} = useAuth()

    let {PID} = useParams();

    const [count, setCount] = useState(1);
    const [Bag, setBag] = useState();
    const [cart, setCart] = useState([])



    const handleIncrement = (type) => {
    //   setCount(count + 1);
      if (type === 'increment') {
        setCount(count + 1);
      } else if (type === 'decrement' && count > 1) {
        setCount(count - 1);
      }

    };

    let BagItems = [

    ]

    const HandleOrder = (item) =>{

         

    }
  
    const handleAddToBag = (currentItem) => {
      console.log(`Added ${count} item(s) to the bag.`);

      alert(`${count} ${currentItem.title}(s) added to cart Successfully!`);
      setCount(1)
    
      setCart((prev) => [
        ...prev,
        {
          Price: currentItem?.price,
          count: count,
          Title: currentItem?.title,
          location: currentItem?.location,

        },
      ]);
      // setCart((prev) => [
      //   ...prev,
      //   {
      //     Price: currentItem?.Price,
      //     count: count,
      //     Title: currentItem?.Title,
      //     location: currentItem?.location,

      //   },
      // ]);

      // setAuth(

      //   (prev) => [
      //   ...prev,
      //   {
      //     "cart" : cart
      //   },
      // ]
      // );

      setAuth((prev) => ({
          ...prev,
          "cart": cart,
        }));



      
    
      console.log("cart", cart);
      console.log("auth", auth);
    };
    


    const cardDatazz = [ 
      { 
         ImgUrl: img1,
         Name: "Snowflake Chartels", 
         Title: "Nike Converse",
         Price: 300,
         location : "Chilanga Post Office, Chilanga, Zambia",
         ID  : 1

   
      },
      { 
         ImgUrl: img1,
         Name: "Zed Watch Guy", 
         Title: "Casio Classic Watch",
         Price: 250,
         location : "Findeco House, Lusaka, Zambia",
         ID  : 2
   
      }, 
       { 
         ImgUrl: img1,
         Name: "Snowflakes Chartel", 
         Title: "Airforce Converse",
         Price: 300,
         location : "Down town mall, Lusaka, Zambia",
         ID  : 3
   
      }, 
       { 
         ImgUrl: img1,
         Name: "Snowflakes Chartel", 
         Title: "Airforce Converse",
         Price: 300,
         location : "Chilanga Post Office, Chilanga, Zambia",
         ID  : 4
   
      }, 
   
   
     ]


    // const cardData = [ 
    //   { 
    //      ImgUrl: img2,
    //      Name: "Pore eclipse Transulucent Powder", 
    //      Title: "Powder",
    //      Price: 300
   
    //   },
    //   { 
    //      ImgUrl: img3,
    //      Name: "Hidro Grip Primer", 
    //      Title: "Hydrating primer",
    //      Price: 250
   
    //   }, 
    //    { 
    //      ImgUrl: img2,
    //      Name: "Future fluid All Over Liquid Concealer", 
    //      Title: "Concealer",
    //      Price: 300
   
    //   }, 
    //    { 
    //      ImgUrl: img3,
    //      Name: "Lip + Cheek", 
    //      Title: "Cream blush + Lip colour",
    //      Price: 300
   
    //   }, 
   
   
    //  ]


    

    
    
  return (

        <div className="ProductOrderContainer"> 
             <ScrollToTop/>
            {/* <ScrollToTop/> */}
            <div className="NavSecContainerProd">
               <NavSec/>
                    {/* <div className="loggedHeader">
                    <div className="Logo" onClick={()=>{console.log("I have been clicked")}}>
                    <Link className="textLink" to="/"><img src={LogoImg} /></Link>
                        
                    </div>
                    <div className="SignIn">
                        <h1>{String(auth?.foundUser?.name).substring(0,1)}</h1>
                      
                    </div>
                    
                  </div> */}

                  </div>
           
            <div className="productNavContainer">
                      {/* <NavBar/> */}
            </div>    
            <div className="minintro">

                <p>Shop All Pore Eclipse Matte Translucent Setting Powder</p>

            </div>
      { cardData && cardData.map((item, index)=>{
        return(
            <> 
            {item.id == PID &&  <div className="ProductDisplay">

                  

                    <div className="ImgVizContainer">       
                            <div className="imgSelf">
                                    <img src={item.imgUrl.fields.file.url} />
                            </div>

                    </div>

                    <div className="CardWordingCont">    
                        <div className="wordingSelf">
                              
                            <div className="VizTitle">
                                    <h2>{item.title}</h2>
                            </div>
                            <div className="VizCategory">
                                    <p>{item.location}</p>
                            </div>
                            <div className="VizIncrementorContainer">

                                     <div className="shopping-basket-button">
                                        <div className="count">
                                        <span className="leftCount" onClick={() => handleIncrement('decrement')}>-</span>
                                            {count}
                                         <span className="rightCount"  onClick={() => handleIncrement('increment')}>+</span>
                                        </div>
                                        <div className="add-to-bag" onClick={() => 
                                             {
                                              handleAddToBag(item)
                                              handleCart(item,count)
                                             
                                             }}>
                                            <p> Add to Bag</p>   
                                        </div>
                                        <div className="price">{` ZMK ${item.price*count}`}</div>
                                        </div>

                                
                            </div>
                            <div className="VizPromotion">
                                    <p>Excluded from promotions.</p>
                            </div>
                            <div>
                              {
                                !Bag ? <>
                                 <Link className="linkItems" to={"/Order"}>  

                                    <div className="orderNowCTA">
                                     

                                         <button onClick={()=> {
                                          // handleOrder()
                                          console.log("bag",cart)
                                          console.log("auth cart",auth)
                                        }
                                          }><h2>Order Now</h2></button>

                                     
                                        
                                    </div>
                                    </Link>
                                    
                                
                                </> : ""
                              }
                            </div>
                            <div className="VizDescriptio"> 
                                    <h2>What it is</h2>
                                    <p>{item.title}</p>

                            </div>
                            <div className="How to Use">    
                                    
                                      <h2>How to Use</h2>
                                      <p>
                                      -Slide the mess-free, locking sifter open and tap powder into cap of jar.
-Swirl brush, puff, or sponge through the powder and tap away excess.
-Apply after makeup application.
-Pro Tip: For an extra matte look, pair with Pore Eclipse Mattifying Primer and Pore Eclipse Matte Setting Spray.
                                      </p>
                                   


                            </div>
                        </div> 
                       

                    </div>

            </div> 

            
            }
            
            </>
        )
      })}
           

            {/* END OF PRODTUCT DISPLAY */}

            <SlideCarousel 
                Heading={"Related items"}

                CarouselData = {cardData}
            
            />

            <Footer/>
                    

                    
           
                

            
        </div>
  );
}

export default ProductOrder;
