import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import useAuth from "../../Hooks/UseAuth";
import useLogout from "../../Hooks/useLogout";
import useContentful from "../../Hooks/Contentful";
import LogoImg from "../../Components/images/ntumaiLogo.png";
import LogoImg1 from "../../Components/images/img1.jpg";
import "./Hero.css";

function Hero({ handleOrderStateSec }) {
  const { getPosters } = useContentful();
  const { auth } = useAuth();
  const logout = useLogout();
  
  const [cardData, setCardData] = useState();
  const [visible, setVisible] = useState(false);

  // useEffect(() => {
  //   getPosters().then(response => response && setCardData(response));
  // }, [getPosters]);

  useEffect(() => {
    getPosters().then(response => response && setCardData(response));
  }, []);

  const handleSignOut = async () => {
    await logout();
    navigate('/');
    console.log("logged out");
  };

  const toggleVisibility = () => setVisible(!visible);

  return (
    <div 
      className="hero-container" 
      style={{ backgroundImage: `url(${cardData?.[0]?.image?.fields?.file?.url || LogoImg1})` }}
    >
      <div className="navbar">
        <div className="logo">
          <img src={LogoImg} alt="Logo" />
        </div>
        <div className="nav-items">
  {auth.foundUser ? (
    <div className="logged-btn">
      <button onClick={toggleVisibility}>
        {auth?.foundUser?.name.charAt(0)}
      </button>
      <div className={`overlay-dropdown ${visible ? 'show' : ''}`}>
        <div className="dropdown-content">
          <div className="email-section">
            <p>{auth?.user}</p>
            <FontAwesomeIcon icon={faTimes} onClick={() => setVisible(false)} />
          </div>
          <div className="icon-section">
            <button onClick={() => {
              toggleVisibility();
              handleOrderStateSec();
            }}>
              <Link className="linkitemhero" to="/Order">ORDERS</Link>
            </button>
          </div>
          <div className="icon-section">
            <button onClick={() => toggleVisibility()}>
              <Link className="linkitemhero" to="/Cart">CART</Link>
            </button>
          </div>
          {auth?.Role === 'Admin' &&
          <div className="icon-section">
            <button onClick={() => toggleVisibility()}>
              <Link className="linkitemhero" to="/Dashboard">DASHBOARD</Link>
            </button>
          </div>}
          <div className="name-section">
            <p>{`Hi, ${auth?.foundUser?.name}!`}</p>
          </div>
          <div className="actions-section" onClick={handleSignOut}>
            <p><FontAwesomeIcon icon={faSignOutAlt} /> Sign Out</p>
          </div>
          <div className="policies-section">
            <p>Privacy policy . Terms of service</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <button className="login-btn">
      <Link className="linkitemhero" to="/login">Login/Sign up</Link>
    </button>
  )}
</div>

      </div>
      <div className="hero-overlay">
        <div className="hero-content">
          <h1>Order Delivery, Errands,<br/>Shopping & more!</h1>
          <p>Pay with cash, card or Mobile money</p>
          <button className="order-btn">
            <Link className="linkitemhero" to="Order">Order Now</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Hero;



// import { Link } from "react-router-dom";
// import LogoImg from "../../Components/images/ntumaiLogo.png"
// import LogoImg1 from "../../Components/images/img1.jpg"
// import useAuth from "../../Hooks/UseAuth";
// import "./Hero.css"
// import { useState,useEffect } from "react";
// import useLogout from "../../Hooks/useLogout";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import AuthContext from "../Context/AuthProvider";
// import { faPlus,faSignOutAlt,faCogs,faSuitcaseRolling,faTools,faUser,faUsers,faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons'
// import useContentful from "../../Hooks/Contentful";
// import axios from "axios";



// function Hero({handleOrderStateSec}){  
    
//      const {getPosters} = useContentful()
//      const [cardData, setCardData] = useState()
    

//     const {auth} = useAuth()

//     const [visible,setVisible] =  useState(false)

//     const logout = useLogout()

//     const SignOut = async () => {
//         await logout();
//         navigate('/')

//         console.log("logged out")
//     }


//     // src/api.js


//     // src/App.js

 

 



//     useEffect(() => {
//         console.log("Hello")
     
//       getPosters().then((response) => response && setCardData(response));
//     //   getPosters().then((response) => response && console.log("hero",response));
//     //    postMessage()
//     },[]);


//   const setSignViz = () =>{
//     setVisible(!visible)

// }


//     return <>
//     <div className="wholeHome"

           
//              style={{
//                 // width: 128,
//                 // height: 193,
//                 backgroundImage: `url(${(cardData && cardData.length>0 && cardData[0]?.image?.fields?.file?.url) || (LogoImg1) })`
//               }}
//     >
          
//            <div onClick={()=> console.log("btn click")} className="NavBar">  
//                <div className="Logo">
//                         {/* <h2>Ntumai</h2> */}
//                         <img src={LogoImg} />
//                </div>

//                <div className="NavItems">
//                  {auth.foundUser?
//                    <div className="loggedBtn"> 
//                      <button onClick={setSignViz} >
//                             {String(auth?.foundUser?.name).substring(0,1)}
//                      </button>  

//                      { visible  &&

//                         //   for logout logic

//                             <div className="overlayDropdown" >

//                                 <div className="DOverlayCover"> 
//                                     <div className="emailSection"> 

//                                             <div className="email"> <p>{String(auth?.user)}</p> </div>
//                                             <div className="cross"><p><FontAwesomeIcon onClick={()=>{setSignViz(false)}} icon={faTimes} className="FontAwesomeIcon"/> </p> </div>
                                            

//                                     </div>
//                                     <div className="iconSection"> 
//                                         <button onClick={
//                                            ()=>{
//                                             setSignViz
//                                             handleOrderStateSec()
//                                             console.log("order handler")
//                                            }
                                            
//                                             }>
//                                         <Link className="textLink" to="/Order"><h2>{"ORDERS"}</h2></Link>

                                                
//                                                 {/* <h2>{ String(auth?.user).substring(0,1).toUpperCase()}</h2> */}
                                            
//                                         </button>

//                                     </div>
//                                     <div className="nameSection"> 
//                                                 <p>{`Hi, `+ String(auth?.foundUser?.name)+`!`}</p>

//                                     </div>
//                                     <div className="actionsSection"> 

//                                             {/* <div className="child"><p><FontAwesomeIcon icon={faPlus} className="FontAwesomeIcon"/> {"Add Account"+`    |    `} </p> </div> */}
//                                             <div className="child" onClick={SignOut}><p><FontAwesomeIcon icon={faSignOutAlt} className="FontAwesomeIcon"/> {``+"Sign Out"}</p>  </div>

//                                     </div>
//                                     <div className="policiesSection"> 

//                                                 <p>Privacy policy . Terms of service</p>

//                                     </div>


//                                 </div>

                                

//                             </div>
//                             }
                                                
                      
//                      </div>

                        
                     
//                       :

//                      <button className="softbtn" onClick={()=> {
                        
//                      console.log("btn click")}}> 
//                           {/* <Link className="textLink" to="Order">Login/Sign up</Link> */}
                        
//                              <Link className="textLink" to="/login">Login/Sign up</Link>
               
                    
//                      </button>

//                  }
                   
//                </div>

//            </div>
//            <div className="HeroOverlay">
//                <div className="contained">
//                    <div className="containedSec">

//                         <div className="Foreword"> 
//                                 <h1>Order Delivery, Errands, <br/>Shopping & more!</h1>
//                                 <p>Pay with cash, card or Mobile money</p>

//                             </div>
//                             {/* <div className="inputField">  
//                                     <input 
//                                      placeholder="Enter your Address"
                                    
//                                     ></input>
                                   
//                                     <button>
//                                         <Link className="textLink" to="Delivery">Order Now</Link>
                                       
//                                     </button>

//                             </div> */}
//                             <div className="specialOrder">  
                                   
//                                     <button>
//                                         <Link className="textLink" to="Order">Order Now</Link>
                                       
//                                     </button>

//                             </div>

//                    </div>
                   

//                </div>
             

//            </div>

//            {/* End of overlay */}
//            <div className="heeae">
//                     {/* <h1>jhhhlhlh</h1> */}
//            </div>

     
//         </div>
        
//     </>

// }

// export default Hero;